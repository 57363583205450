import React, { FC } from "react";
import { PageProps } from "gatsby";
import { Heading } from "../components/Heading";
import { Title } from "../components/Title";
import { TimestampCard } from "../components/Card";
import { Body } from "../components/Body";
import { Seo } from "../components/Seo";
import { BackgroundFromKey } from "../components/Background";
import { Day } from "../types";
import { BackgroundKey, ExplanationKey } from "../enums";
import { Explanation } from "../data/Explanation";

export interface TimestampTemplateProps {
  title: string;
  color: string;
  data: Day[];
  explanation?: ExplanationKey;
  background: BackgroundKey;
}

const TimestampTemplate: FC<PageProps> = ({ pageContext }) => {
  const { title, color, data, explanation, background } =
    pageContext as unknown as TimestampTemplateProps;
  return (
    <>
      <BackgroundFromKey k={background} />
      <Seo
        title={title}
        description={data.map((day) => day.title).join(", ")}
      />
      <Body>
        <Title text={title} className={color} />
        <Explanation k={explanation} />
        <div className="w-full flex flex-col -mt-3">
          {data.map(({ title, stories, route }: Day) => (
            <div
              key={route}
              className="w-full flex flex-col py-5 -my-2"
              id={route}
            >
              <Heading text={title} hash={route} />
              <TimestampCard data={stories} />
            </div>
          ))}
        </div>
      </Body>
    </>
  );
};

export default TimestampTemplate;

import React, { FC, useContext } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { Divider } from "./Divider";
import Text from "./Text";
import { LangContext } from "../context";
import { LangPrefix } from "../enums";

export interface HeadingProps {
  text: string;
  hash: string;
}

export const Heading: FC<HeadingProps> = ({ text, hash }: HeadingProps) => {
  const { langPrefix } = useContext(LangContext);
  return (
    <div className="w-full flex flex-col mb-2">
      <div className="flex w-full justify-center">
        <a
          className="flex-shrink flex justify-center items-center ml-6.5 group space-x-2 min-w-0"
          href={`#${hash}`}
        >
          <Text.Heading className="text-black-custom text-2xl font-bold text-center w-full">
            {text}
          </Text.Heading>
          <LinkIcon
            className={`text-gray-common opacity-0 hover-opacity-50 ${
              langPrefix === LangPrefix.Ko ? "-mt-0.5" : "mt-1"
            }`}
          />
        </a>
      </div>
      <Divider />
    </div>
  );
};
